import React from 'react'

import Layout from 'src/components/layout/index'
import SEO from 'src/components/seo/index'
import Footer from 'src/components/footer/index'

import styles from './index.module.scss'


const IndexPage = () => (
    <React.Fragment>
        <Layout className={styles.notfound}>
            <SEO lang="ja" title="エラー" description="作家・栗原明志(クリハラ アカシ)のウェブサイトです。2019年9月に作品社から『浮きよばなれ〜島国の彼岸へと漕ぎ出す日本文学芸術論』を上梓。" />
        
            <div className={styles.title}>
                <h1>404 Not Found</h1>
                <div className={styles.message}>
                    <p>お探しのページは見つかりませんでした。<br />一時的にアクセスできない状態か移動もしくは削除された可能性があります。</p>
                </div>
            </div>

        </Layout>

        <Footer />

    </React.Fragment>
)

export default IndexPage
